<template>
  <main class="main-content page-questions pt-0 bg-oct" role="main">
    <div>
      <div style="padding-top: 20px" class="row">
        <div class="col-md-6" v-if="prova.titulo != undefined">
          <div class="form-group">
            <label for="titulo"
              >Título <i class="far fa-question-circle" style="color: #d2d2d2"
            /></label>

            <input
              :value="prova.titulo"
              type="text"
              class="form-control"
              :disabled="true"
            />
          </div>
        </div>

        <div class="col-md-3" v-if="prova.ano != undefined">
          <div class="form-group">
            <label for="ano"
              >Ano <i class="far fa-question-circle" style="color: #d2d2d2"
            /></label>
            <input
              :value="prova.ano.titulo"
              type="text"
              class="form-control"
              :disabled="true"
            />
          </div>
        </div>

        <div class="col-md-3" v-if="prova.turma != undefined">
          <div class="form-group">
            <label for="turma"
              >Turma <i class="far fa-question-circle" style="color: #d2d2d2"
            /></label>
            <input
              :value="prova.turma.titulo"
              type="text"
              class="form-control"
              :disabled="true"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="instrucoes"
              >Instruções da Prova
              <i class="far fa-question-circle" style="color: #d2d2d2"
            /></label>
            <!-- <p>{{ prova.instrucoes }} </p>  -->
            <textarea
              id="instrucoes"
              :value="prova.instrucoes"
              rows="5"
              class="form-control"
              :disabled="true"
            />
          </div>
        </div>
      </div>
      <hr />
      <h4 style="padding-top: 1rem;">Questões</h4>
      <div v-if="!editing">
        <questao-list
          v-for="questao in prova.questoes"
          :key="questao.id"
          :questao-prop="questao.questao"
          :ordemProva="prova.ordem"
          :is-list-prova="false"
          :is-list-inserida-prova="true"
          :previewProva="true"
        />
      </div>
      <div v-if="editing">
        <QuestaoListPreview
          v-for="questao in prova.questoes"
          :key="questao.id"
          :questao-prop="questao"
          :ordemProva="prova.ordem"
          :is-list-prova="false"
          :is-list-inserida-prova="true"
          :previewProva="true"
        />
      </div>
    </div>
  </main>
</template>

 
<script>
import moment from "moment";
import QuestaoList from "@/components/questoes/QuestaoList";
import QuestaoListPreview from "@/components/questoes/QuestaoListPreview";

export default {
  components: {
    QuestaoList,
    QuestaoListPreview
  },
  props: {
    prova: { type: Object },
    assuntos: {},
    anos: {},
    turmas: {},
    disciplinas: {},
    questoes: {},
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
      } else {
        return "-";
      }
    },
  },
  created() {
    console.log(this.prova);
    this.prova.turma = this.turmas;
    this.prova.ano = this.anos;
    // this.prova.disciplina = this.disciplinas
  },
  data() {
    return {
      ordensProva: ["Aleatória", "Sequencial"],
    };
  },
};
</script>

<style>
/* .vm{
    max-width: 800px !important;
  } */
</style>
<template>
  <main
    id="content"
    class="main-content page-questions pt-0 bg-oct"
    role="main"
  >
    <div
      v-if="!isCadastraDentroProva"
      class="
        page-questions__header
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <router-link to="/banco-de-questoes" class="link fs-24">
        <i class="fas fa-chevron-left mr-3" /> Voltar
      </router-link>
      <span style="color: #fff; font-size: 20px">Banco de Questões</span>
      <ul class="list-inline m-0">
        <li class="list-inline-item mr-4"></li>
      </ul>
    </div>

    <div class="page-questions__content">
      <!-- Start Content center -->
      <!--<div class="content-left">
        <QuestaoFilter 
          :filtro="filtro"
          :disciplinasDisponiveis="disciplinasDisponiveis"
          :assuntosDisponiveis="assuntosDisponiveis"
        /> -->
    </div>
    <div class="content-right px-4" style="margin: auto">
      <h4 v-if="isCadastraDentroProva" style="color: #d2d2d2">Criar Questão</h4>

      <div
        class="card card-questions mb-4"
        style="border: 6px solid #ddd; padding: 0"
      >
        <div class="card-body mb-3" style="padding: 0">
          <div style="background: #efefef; padding: 20px">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group full-select">
                  <label for="tipo">
                    Tipo
                    <i
                      class="help ml-1"
                      data-toggle="tooltip"
                      title="Some tooltip text!"
                  /></label>
                  <select2
                    id="tipo"
                    v-model="questao.tipo"
                    :settings="{ multiple: false, placeholder: 'Selecione' }"
                    :options="tipos"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="nivel"
                    >Nível de dificuldade
                    <i
                      class="help ml-1"
                      data-toggle="tooltip"
                      title="Some tooltip text!"
                  /></label>
                  <select2
                    id="nivel"
                    v-model="questao.nivel"
                    :settings="{ multiple: false, placeholder: 'Selecione' }"
                    :options="niveis"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group full-select">
                  <label for="disciplina1">
                    Componente Curricular
                    <i
                      class="help ml-1"
                      data-toggle="tooltip"
                      title="Some tooltip text!"
                  /></label>
                  <select2
                    id="disciplina1"
                    v-if="!isCadastraDentroProva"
                    v-model="disciplinaSelecionada"
                    @change="loadAssuntos(disciplinaSelecionada)"
                    :settings="{ multiple: false, placeholder: 'Selecione' }"
                    :options="disciplinas"
                  />
                  <input
                    id="valor"
                    v-model="disciplinaText"
                    disabled
                    type="text"
                    class="form-control"
                    v-if="isCadastraDentroProva"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group full-select">
                  <label for="habilidade">
                    Habilidade
                    <i
                      class="help ml-1"
                      data-toggle="tooltip"
                      title="Some tooltip text!"
                  /></label>
                  <select2
                    id="habilidade"
                    v-model="assuntosSelecionados"
                    :settings="{ multiple: true, placeholder: 'Selecione' }"
                    :options="assuntos"
                  />
                </div>
              </div>
            </div>
          </div>

          <div style="padding: 20px">
            <p class="card-title">Enunciado</p>

            <div class="content mb-2">
                <text-editor
                  v-model="editorData"
                  />
              <!-- <vue-editor
                v-model="editorData"
                useCustomImageHandler
                @image-added="handleImageAdded"
              ></vue-editor>-->
            </div>

            <div class="form-group form-group-repeater d-flex flex-column">
              <div v-if="questao.tipo == 'Objetiva'">
                <p class="card-title">Alternativas</p>
                <div
                  v-for="(alternativa, index) in questao.alternativas"
                  :key="alternativa.id"
                  class="input-group mb-2"
                >
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input
                        v-model="alternativaCorreta"
                        :value="alternativa.id"
                        type="radio"
                        aria-label="Checkbox for following text input"
                        name="alternativaCorreta[]"
                        @change="toggleAlternativa(index)"
                      />
                    </div>
                    <span class="input-group-text">{{ letters[index] }})</span>
                  </div>

                  <text-editor
                    v-model="alternativa.texto"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-danger btn-sm"
                      type="button"
                      style="height: 38px"
                      @click.prevent="deleteAlternativa(index)"
                    >
                      <i class="far fa-trash-alt" />
                    </button>
                  </div>
                </div>

                <button
                  class="btn btn-outline btn-outline__sec ml-auto mt-3"
                  @click="addAlternativa"
                >
                  <i class="mr-1" data-fa-i2svg="">
                    <svg
                      style="height: 20px"
                      class="svg-inline--fa fa-plus fa-w-14"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                      />
                    </svg>
                  </i>
                  Adicionar
                </button>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="float-right">
                    <button
                      type="button"
                      v-if="isCadastraDentroProva == false"
                      class="btn btn-classic"
                      @click.prevent="salvar"
                    >
                      Salvar
                    </button>
                    <!-- <button
                      type="button"
                      v-if="isCadastraDentroProva == false"
                      class="btn btn-secondary m-2"
                      @click="voltaQuestoesProva"
                    >
                      Cancelar
                    </button> -->
                  </div>
                </div>
              </div>
              <div>
                <p style="text-align: right">
                  <button
                    v-if="isCadastraDentroProva"
                    class="btn btn-classic"
                    @click.prevent="salvar"
                  >
                    Salvar Questão
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Content center -->
  </main>
</template>

<script>
import Select2 from "v-select2-component";
import TextEditor from "@/components/global/text-editor/TextEditor";
import Swal from "sweetalert2";
// import QuestaoFilter from '@/components/questoes/QuestaoFilter';
// import { VueEditor, Quill } from "vue2-editor";
import { constantes } from "@/constants";

function initialState (self){
    return {
      content: "",
      disciplinaText: null,
      questao: {
        disciplina: {
          id: null,
        },
        assuntos: [],
        tipo: "Objetiva",
        nivel: "Fácil",
        alternativas: [],
        peso: null,
      },
      tipos: ["Objetiva", "Descritiva"],
      niveis: ["Fácil", "Médio", "Difícil"],
      alternativaCorreta: 0,
      disciplinaSelecionada: null,
      user: [],
      disciplinas: [],
      assuntosSelecionados: [],
      assuntos: [],
      disciplinasDisponiveis: [],
      assuntosDisponiveis: [],
      itensPerPage: 10,
      filtro: {
        page: 1,
        size: 1,
        disciplinaSelecionada: null,
        assuntosSelecionados: null,
        anoSelecionado: null,
        tipo: "",
        search: "",
        origem: "",
      },

      letters: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"],

      editorData: "",
    };
}

export default {
  components: {
    Select2,
    TextEditor
  },

  props: {
    isCadastraDentroProva: {
      type: Boolean,
      required: false,
      default: false,
    },
    disciplinaProva: {},
  },

  data() {
    let self = this;
    console.log(initialState(self));
    return initialState(self);
  },

  created() {
    this.initialLoad();
  },
  methods: {
    voltaQuestoesProva() {
      // this.$router.push('banco-de-questoes');
    },

    initialLoad() {
      this.loadDisciplinas();
      this.loadUser();
      console.log(this.disciplinaProva, this.disciplinaText);
      if (this.isCadastraDentroProva) {
        this.$api.get("disciplinas/" + this.disciplinaProva).then((response) => {
          this.disciplinaText = response.data.text;
          console.log(this.disciplinaText);
        });
      }
    },

    resetWindow() {
      let self = this;
      Object.assign(this.$data, initialState(self));
      this.initialLoad();
    },

    loadUser() {
      this.$api
        .get("users/info")
        .then((user) => {
          console.log(user);
          this.user = user;

          this.loadAssuntos(this.disciplinaProva);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    deleteAlternativa(index) {
      this.questao.alternativas.splice(index, 1);
    },
    changeTipo() {},
    toggleRespostas() {
      this.showRespostas = !this.showRespostas;
    },
    addAlternativa() {
      if (this.questao.alternativas.length < 10) {
        var idx = this.questao.alternativas.length + 1;
        var novaAlternativa = { id: idx, texto: "", isCorreta: false };
        this.questao.alternativas.push(novaAlternativa);
      }
    },
    toggleAlternativa(index) {
      this.questao.alternativas.map(function (entry) {
        entry.isCorreta = false;
      });

      this.questao.alternativas[index].isCorreta = true;
    },
    salvar() {
      if (this.isCadastraDentroProva) {
        this.disciplinaSelecionada = this.disciplinaProva;
      }

      if (!this.editorData || this.editorData == "") {
        Swal.fire("Oops...", "Informe um enunciado!", "error");
        return;
      }

      if (!this.disciplinaSelecionada || this.disciplinaSelecionada == "") {
        Swal.fire("Oops...", "Informe um componente curricular!", "error");
        return;
      }

      if (!this.assuntosSelecionados || this.assuntosSelecionados.length <= 0) {
        Swal.fire("Oops...", "Informe pelo menos uma habilidade!", "error");
        return;
      }

      if (this.questao.tipo == "Objetiva") {
        if (this.questao.alternativas.length < 2) {
          Swal.fire(
            "Oops...",
            "Informe pelo menos duas alternativas!",
            "error"
          );
          return;
        }
        if (this.alternativaCorreta == 0) {
          Swal.fire(
            "Oops...",
            "Marque uma das alternativas como correta!",
            "error"
          );
          return;
        }
      }

      if (this.questao.tipo == "Descritiva") {
        this.questao.alternativas = [];
      }

      let possuiAlternativaSemTexto = this.questao.alternativas.some(alternativa => !alternativa.texto);

      if (possuiAlternativaSemTexto) {
        Swal.fire(
          "Oops...",
          "Todas as alternativas devem ser preenchidas!",
          "error"
        );
        return;
      }

      var obj = {
        enunciado: this.editorData,
        disciplina: this.disciplinaSelecionada,
        assuntos: this.assuntosSelecionados,
        tipo: this.questao.tipo,
        nivel: this.questao.nivel,
        alternativas: this.questao.alternativas,
      };

      if (this.questao.peso != null) {
        obj.peso = this.questao.peso;
      }

      this.$root.$emit("Spinner::show");

      this.$api.post("cadastra-questao", obj).then((response) => {
        this.$root.$emit("Spinner::hide");

        Swal.fire({
          icon: "success",
          title: "Questão cadastrada com sucesso!",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (this.isCadastraDentroProva) {
            response.data.peso = obj.peso;
            this.$parent.depoisCriarQuestaoNaProva(response.data);
          } else {
            // this.voltaQuestoesProva();
            window.location.reload();
          }
        });
      });
    },
    loadDisciplinas() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        console.log(response.data);
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.disciplinas.push(res);
            this.disciplinasDisponiveis.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    loadAssuntos(id) {
      this.assuntos = []
      this.assuntosDisponiveis = []
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      if(!id){
        return false;
      }
      const data = {};
      this.$api.post("assuntos-disciplina/" + id, data).then((response) => {
        console.log(response);
        if (
          idInstituicao == 0
        ) {
          response.data.forEach(res => {
            if(res.instituicao == null){
              this.assuntosDisponiveis.push(res)
              this.assuntos.push(res)
            }
          });
        } else {  
          response.data.forEach((res) => {
            if (res.criadoPor != null && (!res.instituicao || res.criadoPor.email == this.user.data.user.email || res.instituicao.id == idInstituicao)) {
                this.assuntosDisponiveis.push(res);
                this.assuntos.push(res);
            }
          });
        }
        this.$root.$emit("Spinner::hide");
      });
    },
    filtrar() {
      this.$router.push({ name: "questoes" });
    },
    loadQuestoes() {},
  },
};
</script>